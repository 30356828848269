
import { Component, Prop, Vue } from 'vue-property-decorator';
import Locale from '@/models/Locale';
import LocaleRepository from '@/repositories/LocaleRepository';

@Component({
    name: 'LocalePicker',
})
export default class LocalePicker extends Vue {
    @Prop({ required: true }) private selectedLocale!: string | null;

    private get locales() {
        return LocaleRepository.getAll();
    }

    private async created() {
        try {
            await Locale.getAll();
        } catch (e) {
            let error;

            if (e instanceof Error) {
                error = e.message;
            } else {
                error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
            }

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: error,
            });
            return;
        }
    }
}
